import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../layouts/de"
import Seo from "../components/seo"
import SimplePage from "../templates/simple-page"
import BackLink from "../components/back-link"

const content = [
  {
    title: "Kundenzufrieden­heit und -orientierung",
    copy:
      "Wir stehen für anspruchsvolle und hochwertige Produkte, Termintreue und kompetenten Service. In einem langfristig ausgerichteten, partnerschaftlichen Verhältnis erfüllen wir alle machbaren Anforderungen, Vorstellungen und Wünsche unserer Kunden.",
  },
  {
    title: "Qualität",
    copy:
      "Finkernagel-Produkte werden nach dem aktuellsten Stand der Technik hergestellt und bewegen sich auf höchstem Qualitätsniveau. Es ist unser Ziel, diesen Qualitätsmaßstab durch ständige Verbesserung auf allen Ebenen der Organisation zu erhalten und auszubauen.",
  },
  {
    title: "Mitarbeiter­qualifikation und Arbeitssicherheit",
    copy:
      "Unsere fachkundigen und motivierten Mitarbeiter identifizieren sich mit unserer Unternehmenspolitik und sorgen mit einem hohen Maß an Eigenverantwortung für die Umsetzung. Durch die aktive Einbeziehung und fortlaufende Weiterbildung unserer Belegschaft wird unser Know-how ständig ausgebaut. Die Sicherheit unserer Mitarbeiter an ihrem Arbeitsplatz sehen wir als elementares Gut. Daher arbeiten wir stets daran, unseren bisher erreichten Standard im Hinblick auf Arbeitssicherheit kontinuierlich zu verbessern.",
  },
  {
    title: "Nachhaltigkeit",
    copy:
      "Wir nehmen unsere Verantwortung für die Umwelt ernst und schützen diese u.a.  durch den Einsatz modernster Anlagen. Auch das Bewusstsein unserer Mitarbeiter für unsere Umwelt wird gefördert und der betriebliche Umweltschutz ständig verbessert.",
  },
  {
    title: "Prozessorientierung",
    copy:
      "Wir produzieren in beherrschten, stabilen Prozessen. Es ist unser Ziel, durch aussagefähige Kennzahlen die definierten Unternehmensprozesse zu steuern und deren Weiterentwicklung voranzutreiben.",
  },
  {
    title: "Kontinuierliche Verbesserung",
    copy:
      "Finkernagel-Produkte und die zur Herstellung eingesetzten Prozesse und Anlagen werden stetig verbessert und modernisiert. Durch fortschrittliche Lösungen wollen wir unter wirtschaftlichen Gesichtspunkten unsere führende Marktstellung erhalten und weiter ausbauen.",
  },
  {
    title: "Lieferanten",
    copy:
      "Wir arbeiten langfristig mit unseren qualifizierten und zuverlässigen Lieferanten zusammen. Daher ist es unser  erklärtes Ziel, neue Aufgaben in enger Zusammenarbeit zu lösen, um uns gemeinsam weiterzuentwickeln.",
  },
]

const PrivacyPage = () => {
  const { images } = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: { relativePath: { glob: "policy/*.jpg" } }
          sort: { fields: name, order: ASC }
        ) {
          nodes {
            childImageSharp {
              gatsbyImageData(quality: 85, layout: FULL_WIDTH)
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo
        title="Unternehmenspolitik"
        description="Alle Mitarbeiter der Firma Finkernagel sind aufgerufen, sich aktiv an der Umsetzung unserer Unternehmenspolitik zu beteiligen."
      />
      <SimplePage title="Unternehmenspolitik">
        <BackLink to="/company" />
        <p className="intro">
          Finkernagel ist ein erfolgreiches, unabhängiges Familienunternehmen
          mit einer international anerkannten Stellung im Markt. Die
          Geschäftsleitung ruft alle Mitarbeiter auf, sich aktiv an der
          Umsetzung unserer Unternehmenspolitik zu beteiligen.
        </p>

        {content.map((block, i) => (
          <section key={i}>
            {i in images.nodes && (
              <figure className="-mb-2 mt-12">
                <GatsbyImage
                  image={images.nodes[i].childImageSharp.gatsbyImageData}
                  alt={block.title}
                />
              </figure>
            )}
            <h2 className="text-black">{block.title}</h2>
            <p>{block.copy}</p>
          </section>
        ))}
      </SimplePage>
    </Layout>
  )
}

export default PrivacyPage
